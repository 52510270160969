import { z } from 'zod'
import { objectToCamel } from 'ts-case-convert'
import { priceCodeSchema, priceTierSchema } from './price'
import { collectionSchema } from './collection'
import { requireGarmentType } from '../../helpers'

//* ---------------- BASE ---------------- *//
const baseFabricSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  description: z.string().nullable(),
  trinity_fabric_number: z.string(),
  url: z.string(),
  picario_url: z.string().optional().nullable(),
  swatch_url: z.string(),
  has_image: z.boolean().nullable(),
})

const factoryDataSchema = z
  .object({
    in_stock: z.boolean(),
    last_edit_date: z.coerce.date(),
    readiness: z.string(),
    restock_date: z.coerce.date().nullable(),
  })
  .transform(objectToCamel)

const baseFabricExtendedSchema = baseFabricSchema.extend({
  collection_id: z.number().nullable(),
  discount: z.number().nullable(),
  fabric_garment_type: z.number(),
  factories: z.object({
    iD: factoryDataSchema.optional(),
    T2iD: factoryDataSchema.optional(),
  }),
  favorite_id: z.number().nullable(),
  material_type: z.string(),
  pattern_id: z.number().nullable(),
  price_tier: priceTierSchema.nullable(),
  supplier_fabric_number: z.string(),
  trim_garment_type: z.number().nullable(),
  weave_id: z.number().nullable(),
})

const alternateImageSchema = z.object({
  code: z.string(),
  description: z.string(),
  name: z.string(),
  url: z.string(),
})

const compositionSchema = z.object({
  id: z.number(),
  description: z.string(),
})

const fabricPatternSchema = z.object({
  id: z.number(),
  name: z.string(),
})

const fabricWeaveSchema = z.object({
  id: z.number(),
  name: z.string(),
})

//* ---------------- SCHEMAS ---------------- *//
export const fabricSchema = baseFabricSchema.transform(objectToCamel)

export const fabricAutocompleteSchema = baseFabricSchema
  .omit({
    active: true,
    swatch_url: true,
  })
  .transform(objectToCamel)

export const fabricExtendedSchema = baseFabricExtendedSchema.transform(objectToCamel)

export const fabricFullSchema = baseFabricExtendedSchema
  .extend({
    alternate_images: z.array(alternateImageSchema).nullable(),
    collection: z.lazy(() => collectionSchema.nullable()),
    composition: compositionSchema,
    country_origin: z.string(),
    fabric_garment_types: z.array(z.string()),
    fabric_grouping: z.string(),
    fabric_pattern: fabricPatternSchema.nullable(),
    fabric_weave: fabricWeaveSchema.nullable(),
    fabric_year: z.number(),
    lead_time: z.string(),
    pattern: z.string(),
    price_code: priceCodeSchema,
    related_trim: fabricExtendedSchema.nullable(),
    trim_garment_types: z.array(z.string()),
    usage: z.string(),
    weight: z.number().nullable(),
  })
  .transform(objectToCamel)

export const fabricImageSchema = z
  .object({
    id: z.number(),
    base_model_description: z.string(),
    fabric_description: z.string(),
    image: z.string().url(),
    trinity_fabric_number: z.string(),
    garment_type: z.number(),
  })
  .transform(objectToCamel)
  .transform(({ garmentType, ...rest }) => ({ garmentType: requireGarmentType(garmentType), ...rest }))

//* ---------------- TYPES ---------------- *//
declare global {
  module TrinityAPI {
    type FabricType = z.infer<typeof fabricSchema>
    type FabricAutocompleteType = z.infer<typeof fabricAutocompleteSchema>
    type FabricExtendedType = z.infer<typeof fabricExtendedSchema>
    type FactoryType = FabricExtendedType['factories']
    type FabricFullType = z.infer<typeof fabricFullSchema>
    type FabricImageType = z.infer<typeof fabricImageSchema>
  }
}

//* ---------------- HELPERS ---------------- *//
